import React from 'react';
import './App.css';
import InventoryTable from './components/InventoryTable';
import data from './inv.json'; // Coloque el JSON en public o src

function App() {
  return (
    <div className="App">
      <InventoryTable data={data} />
    </div>
  );
}

export default App;