import React, { useState, useEffect } from 'react';
import './InventoryTable.css';

const InventoryTable = ({ data }) => {
  const [filteredData, setFilteredData] = useState(data);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterLevel, setFilterLevel] = useState('');
  const [filterVestir, setFilterVestir] = useState('');
  const [sortColumn, setSortColumn] = useState('Nivel');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    let result = data;

    if (searchTerm) {
      result = result.filter(item =>
        item.Nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.Descripcion_Corta.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (filterType) {
      result = result.filter(item => item.Tipo === filterType);
    }

    if (filterLevel) {
      result = result.filter(item => item.Nivel === parseInt(filterLevel));
    }

    if (filterVestir) {
      result = result.filter(item => item.Vestir.includes(filterVestir));
    }

    result.sort((a, b) => {
      if (sortOrder === 'asc') {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
    });

    setFilteredData(result);
  }, [searchTerm, filterType, filterLevel, filterVestir, sortColumn, sortOrder, data]);

  const handleSort = (column) => {
    setSortColumn(column);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <div>
      <h1>Inventario Petria</h1>
      <div className="filters">
        <input
          type="text"
          placeholder="Buscar por Nombre o Descripcion_Corta"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select onChange={(e) => setFilterType(e.target.value)}>
          <option value="">Buscar por Tipo</option>
          {Array.from(new Set(data.map(item => item.Tipo))).map(type => (
            <option key={type} value={type}>{type}</option>
          ))}
        </select>
        <select onChange={(e) => setFilterVestir(e.target.value)}>
          <option value="">Buscar por Vestir</option>
          {Array.from(new Set(data.flatMap(item => item.Vestir))).map(vestir => (
            <option key={vestir} value={vestir}>{vestir}</option>
          ))}
        </select>
        <input
          type="number"
          placeholder="Buscar por Nivel"
          onChange={(e) => setFilterLevel(e.target.value)}
        />
      </div>
      <table>
        <thead>
          <tr>
            <th onClick={() => handleSort('Nombre')}>Nombre</th>
            <th onClick={() => handleSort('Nivel')}>Nivel</th>
            <th>Area_Definicion</th>
            <th onClick={() => handleSort('Vestir')}>Vestir</th>
            <th>Extra_Flags</th>
            <th>Damage_Avg</th>
            <th>Damage_Dice</th>
            <th>Armor_Protections</th>
            <th>Spells</th>
            <th>Afectaciones</th>
            <th>Extra_Data</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map(item => (
            <tr key={item.VNUM}>
              <td>{item.Nombre}</td>
              <td>{item.Nivel}</td>
              <td>{item.Area_Definicion}</td>
              <td>{item.Vestir.join(', ')}</td>
              <td>{item.Extra_Flags}</td>
              <td>{item.Damage_Avg}</td>
              <td>{item.Damage_Dice}</td>
              <td>{JSON.stringify(item.Armor_Protections)}</td>
              <td>{item.Spells?.join(', ')}</td>
              <td>{item.Afectaciones.map(aff => `${aff.Location}: ${aff.Modifier}`).join(', ')}</td>
              <td>{item.Extra_Data ? Object.entries(item.Extra_Data).map(([key, value]) => `${key}: ${value}`).join(', ') : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InventoryTable;
